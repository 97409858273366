.ms-Button {
    &--default {
      padding: 1.3rem 2.5rem;
      background-color: transparent;
      border-color: #ceaa69;
    }
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .ant-message-notice-content{
    padding:0px !important;
    border-radius: 0px !important;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .ms-MessageBar-innerText {
    margin: 0px auto;
  }
  
  .is-disabled {
    background-color: rgba(128, 128, 128, 0.205) !important;
  }
  
  .buttonwithloader {
    background-color: #008000ab; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  
    &:disabled {
      opacity: 0.5;
    }
  }
  
  .page_cont {
    position: absolute;
    top: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  option {
    background-color: black;
  }
  
  .nav-bar {
    .mobile-nav-logo {
      display: none;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  @media (max-width: 1100px) {
    .swap {
      .title {
        justify-content: center;
      }
  
      &.swap-page {
        .pad-main-body {
          padding-right: 0px;
  
          &.second {
            width: 80%;
          }
        }
  
        .notif {
          width: auto;
          text-align: center;
        }
      }
    }
  
    .notif {
      width: 100%;
      color: red;
      margin: 0px 5px !important;
    }
  
    .nav-bar {
      .nav-logo {
        display: none;
      }
      .mobile-nav-logo {
        display: block;
  
        img {
          width: 35px;
          margin-left: 40%;
        }
      }
    }
    .nav-children {
      display: flex;
      justify-content: space-between;
      width: 90% !important;
      align-items: center;
      cursor: pointer;
  
      .ms-Button {
        padding: 0rem 1.2rem;
        width: fit-content;
        overflow: hidden;
        font-size: 0.6rem;
        text-align: center;
        /* border-width: 0px; */
        padding-right: 0rem;
        padding-left: 0.2rem;
      }
    }
  
    .body-not-centered {
      &.swap {
        &.swap-page {
          .swap-main {
            display: block;
  
            .swap-from {
              width: 100%;
            }
  
            .icon {
              margin: 0px auto;
  
              .arrow {
                transform: rotate(90deg);
              }
            }
          }
          .swap-entry {
            width: auto;
  
            .ms-Button {
              width: 80%;
            }
  
            &.swap-buttons {
              width: 70%;
            }
          }
  
          .pad-main-body {
            &.second {
              width: 80%;
            }
  
            .sel {
              display: block;
            }
          }
        }
  
        &.liquidity {
          .header {
            &.title {
              display: block;
              width: fit-content;
            }
          }
  
          select {
            margin-top: 1rem;
          }
        }
      }
    }
  
    .centered-body {
      &.liquidity1 {
        .pad-main-body {
          padding-top: 1rem;
  
          .liqu-details {
            .value {
              font-size: 1rem;
            }
          }
        }
      }
  
      .bottom-stick {
        margin-bottom: 1.5rem;
      }
  
      .liqu-details {
        width: 100%;
        display: block;
        justify-content: center;
        text-align: center;
  
        p {
          margin-bottom: 0px;
        }
  
        .my-liqu-details {
          max-width: inherit;
  
          .value {
            font-size: 1.3rem;
            height: 35px;
          }
  
          p {
            font-size: 0.5rem;
          }
        }
  
        .ms-Button {
          width: 80%;
          margin-top: 1rem;
        }
      }
    }
  }
  
  .liqu-details {
    margin: 0px auto !important;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    margin-top: 0px;
  
    button {
      width: 40%;
      height: 60px;
      border-color: #ceaa69;
      background-color: transparent;
      border-radius: 10px;
      border-left-style: dotted;
    }
  
    .my-liqu-details {
      display: block;
      margin: 0px;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
  
      p {
        display: flex;
        justify-content: center;
        align-items: center;
  
        svg {
          font-size: 10px;
          line-height: 2px;
        }
      }
  
      .value {
        background-color: #25282d00;
        border: none;
        color: #ffffff;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        height: 55px;
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
  
        span {
          margin-left: 0.5rem;
          font-size: 13px;
        }
      }
  
      p {
        text-align: center;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
    .ms-TextField-fieldGroup {
      border: none;
      background: transparent;
    }
  }
  
  .centered-body {
    padding-top: 0%;
  
    button {
      &.is-disabled {
        border-color: grey;
      }
    }
  }
  
  .emphasize {
    font-weight: bold;
    color: #ceaa69;
  }
  
  .centered {
    text-align: center;
  }
  
  .no-display {
    display: none;
  }
  
  .bold {
    font-weight: 500;
  }
  
  .title {
    width: fit-content;
    margin: auto 0px;
    cursor: pointer;
  }
  
  .liquidity-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }
  
  .notif {
    color: red;
  }
  
  .vert-divider {
    border-left-width: thin;
    border-color: white;
    border-style: solid;
    padding: 0.8rem 0rem;
  }
  
  .icon {
    top: 100%;
    /* left: 50%; */
    transform: translate(-50%, -50%);
    width: 80px;
    height: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
  }
  
  .arrow {
    position: absolute;
    top: 90%;
    width: 50%;
    left: 65%;
    height: 10px;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .arrow::after,
  .arrow::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    right: -8px;
    background-color: #fff;
  }
  
  .arrow::after {
    top: 6px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(-45deg);
  }
  
  .arrow::before {
    top: -5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(45deg);
  }
  
  .body-not-centered {
    padding-top: 1%;
    margin: 0px auto;
    width: auto;
  
    & > * {
      margin: 3.5rem 10rem;
    }
  
    &.swap {
      .swap-main {
        display: flex;
        justify-content: space-between;
        -webkit-box-shadow: 1px 1px 1px 1px #ccc;
        -moz-box-shadow: 3px 3px 5px 6px #ccc;
        box-shadow: -1px 2px 6px 2px #cccccc47;
        border-radius: 15px;
        padding: 20px 10px;
  
        .swap-from,
        .swap-to {
          .sub-tit {
            font-size: 0.75rem;
          }
          border-radius: 16px;
          background-color: #eeeaf4;
          box-shadow: 0px 2px 2px -1px inset;
          width: 40%;
          padding: 0.7rem 1.2rem;
          /* text-align: center; */
          color: black;
          height: 5rem;
          font-weight: 600;
  
          .ms-TextField-fieldGroup {
            margin-top: 0.1rem;
            width: 50%;
            border-width: 0px;
            padding: 0px;
  
            &.fieldGroup-69 {
              border: 0.5px white;
              border-width: 0px;
  
              &::after {
                border: none;
                outline: none !important;
                border-width: 0px;
              }
            }
  
            input {
              padding: 0px;
              color: black;
              font-size: 1.5rem;
              font-weight: bold;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
  
            ::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: rgb(136, 136, 136);
              font-size: 2rem;
              font-weight: bold;
            }
  
            ::-moz-placeholder {
              /* Firefox 19+ */
              color: rgb(136, 136, 136);
              font-size: 1.5rem;
              font-weight: bold;
            }
  
            :-ms-input-placeholder {
              /* IE 10+ */
              color: rgb(136, 136, 136);
              font-size: 1.5rem;
              font-weight: bold;
            }
  
            :-moz-placeholder {
              /* Firefox 18- */
              color: rgb(136, 136, 136);
              font-size: 1.5rem;
              font-weight: bold;
            }
          }
        }
  
        .swap-to {
          border-radius: 16px;
          background-color: rgb(238, 234, 244);
          box-shadow: 0px 2px 2px -1px inset;
          width: 40%;
          padding: 1rem;
          color: black;
        }
      }
  
      .header {
        justify-content: center;
        &.title {
          justify-content: space-between;
          display: flex;
          width: 100%;
        }
  
        select {
          width: 100%;
          border-radius: 5px;
          font-weight: 600;
          background-color: white;
          padding: 0.5rem;
          border-color: #ceaa69;
          background-color: transparent;
        }
      }
      .pad-main-body {
        width: 80%;
        padding-left: 0rem;
        padding-top: 1em;
  
        .sel {
          .header {
            margin-bottom: 0px;
          }
        }
  
        .header {
          font-weight: 500;
          margin-bottom: 0.9em;
          margin-top: 0.1em;
          display: flex;
          justify-content: end;
          align-items: flex-end;
        }
  
        .content {
          margin-top: 0.8em;
          font-size: 0.9em;
          height: 3.5em;
        }
  
        .space-out {
          display: flex;
          justify-content: space-between;
  
          &.swap-entry {
            justify-content: space-around;
  
            &.swap-buttons {
              width: 50%;
            }
          }
        }
  
        &.second {
          width: 50%;
          padding-top: 1em;
        }
      }
  
      .swap-entry {
        border-radius: 10px;
        margin: 0px auto;
        justify-content: center;
  
        .ms-TextField {
          width: 100%;
        }
        .ms-TextField-fieldGroup {
          width: 100%;
          height: 40px;
          border-right-width: 0px;
        }
        .ms-Button {
          width: 40%;
          height: 60px;
          border-color: #ceaa69;
          background-color: transparent;
          border-radius: 10px;
          border-left-style: dotted;
  
          &:disabled {
            border-color: grey;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
  
        input[type="number"] {
          -moz-appearance: textfield; /* Firefox */
        }
      }
    }
  }
  
  .body-not-centered > *:first-child {
    margin: auto 0px;
  }
  
  .verify {
    justify-content: space-around;
    display: flex;
    width: 60%;
  }
  .content {
    &.itemlist {
      margin-bottom: 0px;
    }
  }
  .pad-main-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    margin: 0px auto;
  
    &.verify {
      justify-content: space-around;
      display: flex;
      width: 50%;
    }
  
    .successful-cont {
      display: flex;
  
      & > *:nth-child(2) {
        padding-left: 1em;
      }
    }
  
    .content {
      margin-top: 0.8em;
      font-size: 0.9em;
    }
  
    .header {
      font-weight: 500;
      margin-bottom: 0.5em;
      margin-top: 0.1em;
      font-size: 1.1em;
      text-transform: capitalize;
    }
  
    .ms-Button {
      &.root-44 {
        padding: 1.3rem 2.5rem;
        background-color: transparent;
        border-color: #ceaa69;
        width: 100%;
      }
    }
  
    .token-select {
      width: auto;
  
      select {
        width: 10%;
      }
    }
  
    select {
      width: 100%;
      border-radius: 5px;
      font-weight: 600;
      background-color: white;
      padding: 0.5rem;
      border-color: #ceaa69;
      background-color: transparent;
    }
    .ms-TextField-wrapper {
      border-radius: 1px;
    }
    .ms-TextField-fieldGroup {
      background-color: transparent;
      border-radius: 1px;
      padding: 10px;
      height: auto;
      font-weight: bold;
      border: 1px solid #ced4da;
      input {
        background-color: transparent;
        font-size: 0.9rem;
        height: 1.7rem;
        border-radius: 1px;
      }
    }
  
    .fieldGroup-54 {
      width: 80%;
      height: 3.2em;
  
      border-color: #ceaa69;
    }
  }
  
  .pad-main-body > * {
    margin: 0.8rem auto;
  }
  
  .main-header {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }
  
  .drawer {
    .ant-drawer-content-wrapper {
      width: 520px !important;
    }
  }
  
  @media (max-width: 450px) {
    .ms-Modal-scrollableContent {
      width: 90%;
      margin: 0px auto;
    }
  
    .ant-message-notice-content {
      width: 90%;
    }
  
    .ant-result-extra {
      overflow: hidden;
    }
  
    .drawer {
      .ant-drawer-content-wrapper {
        width: 90% !important;
      }
    }
  }
  
  @media (max-width: 1100px) {
    .main-header {
      text-align: center;
    }
  
    .header {
      &.title {
        text-align: center;
        margin: 0px auto;
        justify-content: center !important;
      }
    }
  
    .full-screen-page {
      .contents-container {
        padding-left: 0%;
      }
  
      .pad-main-body {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 2rem;
        margin: 0px auto;
        width: 95%;
  
        &.verify {
          display: block;
          margin: 0px auto;
          text-align: center;
          padding-top: 0.5rem;
        }
      }
    }
  }
  
  .contents-container {
    padding-left: 10%;
  
    .main-header {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.02em;
      text-transform: capitalize;
    }
  
    .body-content {
      padding-top: 10%;
      margin: 0px auto;
      width: auto;
      text-align: center;
  
      .ms-Button {
        &.root-44 {
          margin-top: 2rem;
          padding: 1.3rem 2.5rem;
          background-color: transparent;
          border-color: #ceaa69;
        }
      }
    }
  }
  
  .ms-Modal {
    .body-42 {
      padding: 20px;
      margin: 20px 20px;
      padding-left: 10px;
  
      border-width: 3px;
      border-radius: 10px;
      border-style: solid;
      border-color: #69656524;
      justify-content: space-between;
      display: flex;
  
      p {
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
  
      img {
        width: 30px;
      }
    }
  
    .body-42:hover {
      border-width: 1px;
      border-style: solid;
      border-color: white;
    }
  }
  
  .ant-steps-item-title {
    font-size: 12px !important;
  }
  
  .bottom-stick {
    width: 100%;
    text-align: center;
    /* position: absolute; */
    /* bottom: 0px; */
    margin-top: 2em;
  }  